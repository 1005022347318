import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'gatsby';
import { theme } from '../Theme/Theme';

export interface NavLinkProps {
  className?: string;
  to: string;
  label: string;
}

const NavLinkInner = (props: NavLinkProps) => {
  const { label, to } = props;
  return (
    <Link className={props.className} activeClassName="active" to={to}>
      <div className={'label-wrapper'}>{label}</div>
    </Link>
  );
};

export const NavLink = styled(NavLinkInner)`
  /* General style */
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all ${theme.hover.transitionTime} ease-in-out;

  /* Default style */
  color: ${theme.color.primaryDark};
  &:hover {
    color: ${theme.color.primary};
  }
  &.active {
    color: ${theme.color.primary};
    } 
  }

  /* Inverse style*/
  &.inverse {
    color: ${theme.color.light};
    &:hover {
      color: ${theme.color.primary};
    }
    &.active {
      color: ${theme.color.primary};
      } 
  }

  /* Dynamic style */
  &.dynamic {
    &:hover {
      color: ${theme.color.primary};
      transform: scale(1.2);
    }
    &.active {
      color: ${theme.color.primary};
      transform: scale(1.2);
      } 
  }

  /* Button style*/
  &.button {
    &:hover {
      .label-wrapper {
        background: transparent;
        color: ${theme.color.primary};
      }
    }
    &.active {
      .label-wrapper {
        background: transparent;
        color: ${theme.color.primary};
        border: none;
      }
    }
    .label-wrapper {
      background: ${theme.color.primary};
      color: ${theme.color.light};
      padding: 10px 15px;
      border: 3px solid ${theme.color.primary};
      transition: all ${theme.hover.transitionTime} ease-in-out;
    }  
`;
