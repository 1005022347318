import { Link } from 'gatsby';
import { styled } from 'linaria/react';
import React from 'react';
import { routes } from '../../../../lib/routes';
import LogoLightLinkSVG from '../assets/kmw_dark.svg';
import LogoLinkSVG from '../assets/kmw_light.svg';

export interface LogoLinkProps extends React.HTMLAttributes<HTMLElement> {
  light?: boolean;
}

const LogoLinkInner: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
  const { light = false } = props;
  return (
    <Link to={routes.home.to} className={props.className}>
      <img src={light ? LogoLightLinkSVG : LogoLinkSVG} />
    </Link>
  );
};

export const LogoLink = styled(LogoLinkInner)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
