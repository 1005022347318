import React from 'react';
import Container from '@material-ui/core/Container';
import { styled } from 'linaria/react';
import { theme } from '../Theme/Theme';

export interface ContainerCustomProps {
  className?: string;
  children: JSX.Element | JSX.Element[];
}

const ContainerCustomInner = (props: ContainerCustomProps) => {
  return (
    <Container maxWidth="xl" className={props.className}>
      {props.children}
    </Container>
  );
};

export const ContainerCustom = styled(ContainerCustomInner)`
  @media (min-width: ${theme.breakpoints.md}px) {
    padding: 0 7vw !important;
  }
  @media (min-width: ${theme.breakpoints.lg}px) {
    padding: 0 8vw !important;
  }
  @media (min-width: ${theme.breakpoints.xl}px) {
    padding: 0 14vw !important;
  }
`;
