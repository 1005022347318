import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { GlobalStyle } from '../Theme/components/GlobalStyle';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { styled } from 'linaria/react';
import { muiTheme } from '../Theme/Theme';

export interface LayoutProps {
  transparentHeader?: boolean;
  children?: ReactNode | ReactNode[];
}

const StyledMain = styled.main<{ mt?: boolean }>`
  margin-top: ${props => (props.mt ? '80px' : '0px')};

  ${muiTheme.breakpoints.down('xs')} {
    max-width: 100vw;
    overflow: hidden;
  }
`;

const Layout = (props: LayoutProps) => {
  const { children, transparentHeader } = props;
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className={GlobalStyle}>
          <Helmet title={data.site.siteMetadata.title}>
            <html lang="en" />
          </Helmet>
          <Header transparent={transparentHeader} />
          <StyledMain mt={!transparentHeader}>{children}</StyledMain>
          <Footer />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
