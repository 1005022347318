export interface Route {
  label: string;
  to: string;
  displayInMenu?: boolean;
}

export const routes = {
  home: {
    label: 'Domov',
    to: '/'
  },
  traingings: {
    label: 'Tréningy',
    to: '/treningy/'
  },
  warrior: {
    label: 'Krav Maga Warrior',
    to: '/krav-maga-warrior/'
  },
  contact: {
    label: 'Kontakt',
    to: '/kontakt/'
  },
  signUp: {
    label: 'Zapísať sa do KMW',
    to: '/zapis/',
    displayInMenu: false
  }
};
