import { createMuiTheme } from '@material-ui/core';
import { darken, lighten, rgba, shade } from 'polished';

export const muiTheme = createMuiTheme({});

const primaryColor = '#e40203'; // red
const primaryLight = '#fefefe';

export const theme = {
  name: 'Default',
  color: {
    background: primaryLight,
    primary: primaryColor,
    secondary: '#01304c', // dark blue
    primaryDark: '#5c5c5c',
    gray: '#f1f1f1',
    grayDark: '#d9d7d8',
    light: primaryLight
  },
  hover: {
    shade: 0.5,
    transformScale: 1.1,
    transitionTime: 200
  },
  borderRadius: '16px',
  spacing: {
    sectionMarginTop: '7vh'
  },
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1280,
    xl: 1600
  }
};
