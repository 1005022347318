import React from "react";
import { styled } from "linaria/react";
import { FooterBottomSection } from "./components/FooterBottomSection/FooterBottomSection";
import { FooterTopSection } from "./components/FooterTopSection/FooterTopSection";
import { theme } from "../Theme/Theme";

export interface FooterProps {
  className?: string;
}

const FooterInner = (props: FooterProps) => {
  return (
    <footer className={props.className}>
      <FooterTopSection />
      <FooterBottomSection />
    </footer>
  );
};

export const Footer = styled(FooterInner)`
  margin-top: ${theme.spacing.sectionMarginTop};
`;