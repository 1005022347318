import React from 'react';
import { styled } from 'linaria/react';
import { Container, Grid, Hidden } from '@material-ui/core';
import { muiTheme, theme } from '../../../Theme/Theme';
import { shade } from 'polished';
import { Socials } from '../../../Socials/Socials';
import { routes } from '../../../../../lib/routes';
import { NavLink } from '../../../NavLink/NavLink';
import { LogoLink } from '../../../Header/components/LogoLink';
import { ContainerCustom } from '../../../ConatinerCustom/ContainerCustom';
import { Link } from 'gatsby';
import { KVButton, KVButtonStyles } from '../../../KVButton/KVButton';

export interface FooterTopSectionProps {
  className?: string;
}

const GdprLink = styled.a`
  font-size: 1.8rem;
  text-decoration: none;
  color: ${theme.color.primaryDark};
  margin-top: 30px;
  display: flex;
  align-items: center;

  .gdpr__square {
    display: flex;
    background-color: ${theme.color.primary};
    height: 10px;
    width: 10px;
    margin-right: 10px;
  }

  ${muiTheme.breakpoints.down('sm')} {
    margin: 10px auto;
  }
`;

const FooterTopSectionInner = (props: FooterTopSectionProps) => {
  return (
    <div className={props.className}>
      <ContainerCustom>
        <Grid container spacing={4}>
          <Grid
            item
            sm={12}
            md={4}
            container
            direction="column"
            alignItems="flex-start"
          >
            <div className="footer__logo-link">
              <LogoLink />
            </div>
            <GdprLink
              href="/ochrana-osobnych-udajov.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="gdpr__square" />
              Pravidlá ochrany GDPR
            </GdprLink>
          </Grid>
          <Grid item sm={12} md={8} container spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={3} container alignItems="center">
              <Socials className="footer__socials" />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={1} />
            </Hidden>
            <Grid item xs={12} lg={8} md={9} className="footer__btn-links">
              <Link className="footer__btn-link" to={routes.signUp.to}>
                <KVButton small>Staň sa Krav Maga Warrior!</KVButton>
              </Link>
              {/* <Link className="footer__btn-link" to={routes.signUp.to}>
                <KVButton small>E-book nauč sa brániť</KVButton>
              </Link> */}
            </Grid>
            <Grid item xs={12}>
              <div className="nav-items">
                {Object.values(routes)
                  .filter(route => route.displayInMenu !== false)
                  .map(route => (
                    <NavLink
                      className="nav-items__link"
                      key={route.to}
                      to={route.to}
                      label={route.label}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </ContainerCustom>
    </div>
  );
};

export const FooterTopSection = styled(FooterTopSectionInner)`
  padding: 50px 0 40px;
  background: ${theme.color.gray};
  text-align: center;
  display: flex;
  .container {
    display: flex;
  }
  .footer-link {
    color: ${theme.color.primaryDark};
    margin: auto;
    :hover {
      color: ${shade(theme.hover.gshade, theme.color.primaryDark)};
    }
  }
  .nav-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }

  .footer {
    &__btn-link {
      display: flex;
      align-items: center;
    }

    &__socials {
      flex: 1;
    }
    &__btn-links {
      display: flex;
      justify-content: center;

      ${muiTheme.breakpoints.down('lg')} {
        justify-content: center !important;
      }
    }
  }

  img {
    height: 80px;
  }

  ${muiTheme.breakpoints.down('sm')} {
    img {
      height: 55px;
    }

    .nav-items {
      margin-top: 30px;
      display: flex;
      flex-flow: column;

      &__link {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }

    .footer {
      &__logo-link {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &__socials {
        justify-content: space-evenly;
      }

      &__btn-links {
        flex-flow: column;
        margin-top: 25px;
      }

      &__btn-link {
        div {
          flex: 1;
        }
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }
`;
