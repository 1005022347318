import Hidden from '@material-ui/core/Hidden';
import { Link } from 'gatsby';
import { css } from 'linaria/lib';
import { styled } from 'linaria/react';
import React from 'react';
import { routes } from '../../../lib/routes';
import { ContainerCustom } from '../ConatinerCustom/ContainerCustom';
import { KVButton } from '../KVButton/KVButton';
import { theme } from '../Theme/Theme';
import { LogoLink } from './components/LogoLink';
import { MobileMenu } from './components/MobileMenu';
import { useLocation } from '@reach/router';
import { RiRouterFill } from 'react-icons/ri';

const NavLink = css`
  font-size: 2rem;
  font-weight: 600;

  color: ${theme.color.background};
  padding: 0 20px;
  text-transform: uppercase;

  transition: all 230ms ease-in-out;

  &:hover,
  &.active {
    color: ${theme.color.primary} !important;
    transform: scale(1.05);
  }
`;

const renderNavItems = () =>
  Object.values(routes)
    .filter(item => item.displayInMenu !== false)
    .map(route => (
      <Link
        className={NavLink}
        key={route.to}
        activeClassName="active"
        to={route.to}
      >
        {route.label}
      </Link>
    ));

const HeaderStyled = styled.header<{ transparent?: boolean }>`
  display: flex;
  height: 80px;
  background: ${props =>
    props.transparent ? 'transparent' : theme.color.gray};
  align-items: center;
  position: absolute;
  top: ${props => (props.transparent ? '20px' : 0)};
  right: 0;
  left: 0;
  z-index: 100;

  nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .nav-items {
    display: flex;
    align-items: center;
    a {
      color: ${props => (!props.transparent ? 'initial' : theme.color.gray)};
    }
  }
`;

interface HeaderProps {
  transparent?: boolean;
}

export const Header = (props: HeaderProps) => {
  const { transparent = false } = props;
  const { pathname } = useLocation();
  return (
    <HeaderStyled transparent={transparent}>
      <ContainerCustom>
        <nav>
          <LogoLink light={transparent} />
          <Hidden mdDown>
            <div className="nav-items">
              {renderNavItems()}
              <Link className={NavLink} to={routes.signUp.to}>
                <KVButton hovered={pathname === routes.signUp.to} small>
                  {routes.signUp.label}
                </KVButton>
              </Link>
            </div>
          </Hidden>
          <MobileMenu />
        </nav>
      </ContainerCustom>
    </HeaderStyled>
  );
};
