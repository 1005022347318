import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme/Theme';

export interface FooterBottomSectionProps {
  className?: string;
}

const currentYear = new Date().getFullYear();

const FooterBottomSectionInner = (props: FooterBottomSectionProps) => {
  return (
    <div className={props.className}>
      kravmagawarior.sk {currentYear} Všetky práva vyhradené.
    </div>
  );
};

export const FooterBottomSection = styled(FooterBottomSectionInner)`
  background: ${theme.color.secondary};
  color: ${theme.color.grayDark};
  text-align: center;
  padding: 20px 0;
  font-size: 1.6rem;
`;
