import React from 'react';
import { TiSocialInstagram, TiSocialYoutube } from 'react-icons/ti';
import { RiFacebookFill } from 'react-icons/ri';
import { styled } from 'linaria/react';
import { css } from 'linaria/lib';
import { theme } from '../Theme/Theme';

export type SocialsProps = React.HTMLAttributes<HTMLElement>;

const Social = css`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background: ${theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.color.light};
  transition: all ${theme.hover.transitionTime}ms ease-in-out;
  font-size: 3rem;
  :hover {
    transform: scale(${theme.hover.transformScale});
  }
`;

const SocialLinks = [
  {
    name: 'Facebook',
    icon: <RiFacebookFill />,
    url: 'https://www.facebook.com/KravMagaWarrior1'
  },
  {
    name: 'Intagram',
    icon: <TiSocialInstagram />,
    url: 'https://www.instagram.com/kravmaga_warrior_ba/'
  },
  {
    name: 'Youtube',
    icon: <TiSocialYoutube />,
    url: 'https://www.youtube.com/channel/UCu6Xc6ApzzVbFG2jxHITNvQ/featured'
  }
];

const SocialsInner: React.FC<SocialsProps> = (props: SocialsProps) => {
  return (
    <div className={props.className}>
      {SocialLinks.map(link => (
        <a
          className={Social}
          aria-label={link.name}
          key={link.url}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.icon}
        </a>
      ))}
    </div>
  );
};

export const Socials = styled(SocialsInner)<SocialsProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
