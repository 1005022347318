import cls from 'classnames';
import { css } from 'linaria';
import React, { ReactNode } from 'react';
import { theme } from '../Theme/Theme';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface KVButtonProps extends React.HTMLProps<HTMLDivElement> {
  small?: boolean;
  secondary?: boolean;
  loading?: boolean;
  narrow?: boolean;
  blue?: boolean;
  children?: ReactNode;
  className?: string;
  hovered?: boolean;
}

export const KVButtonStyles = css`
  background: ${theme.color.primary};
  color: ${theme.color.background};
  padding: 18px;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  border: 3px solid ${theme.color.primary};
  transition: all 230ms ease-in-out;
  justify-content: center;

  &.hovered,
  &:hover {
    background: transparent;
    color: ${theme.color.primary};
  }

  &.small {
    padding: 14px;
  }

  &.secondary {
    color: ${theme.color.secondary};
    border-color: ${theme.color.grayDark};
    background: transparent;
    border-width: 2px;
  }

  &.loading {
    filter: opacity(0.5);
    cursor: progress;
  }

  &.narrow {
    max-width: 250px;
    margin: auto;
  }

  &.blue {
    background: #4d68ac;
    border-color: #4d68ac;
    :hover {
      background: transparent;
      color: #4d68ac;
    }
  }
`;

export const KVButton = (props: KVButtonProps) => {
  const {
    children,
    small = false,
    secondary = false,
    loading = false,
    narrow = false,
    blue = false,
    hovered = false,
    className = ''
  } = props;

  return (
    <div
      {...props}
      className={cls({
        [KVButtonStyles]: true,
        [className]: true,
        small,
        secondary,
        loading,
        narrow,
        blue,
        hovered
      })}
    >
      {children}
      {loading && (
        <CircularProgress
          style={{
            height: 18,
            width: 18,
            color: theme.color.primary,
            marginLeft: 10
          }}
        />
      )}
    </div>
  );
};
